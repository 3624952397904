// From Dan Abramov: https://overreacted.io/making-setinterval-declarative-with-react-hooks/

import { useEffect, useRef } from 'react';

export function useInterval(callback, delay, wait = false) {
    const isRunning = useRef(false)
    const savedCallback = useRef();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback])

    useEffect(() => {
        const tick = async () => {
            if (!(wait && isRunning.current)) {
                isRunning.current = true
                try {
                    await savedCallback.current();
                } catch (error) {
                    console.error('Error during useInterval')
                } finally {
                    isRunning.current = false
                }
            }
        }
        if (delay !== null && delay > 0) {
            const id = setInterval(tick, delay)
            return () => {
                clearInterval(id);
            };
        }
    }, [delay, wait]);
}